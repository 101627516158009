<template>
	<v-container fluid>
		<v-card>
			<v-card-title>
				<span class="headline">{{ formTitle }}</span>
			</v-card-title>

			<v-card-text>
				<v-form ref="form" enctype="multipart/form-data" lazy-validation>
					<!-- Preview Image -->
					<v-img
						v-if="imageUrl"
						:src="imageUrl"
						max-height="200"
						max-width="300"
					></v-img>
					<v-img
						v-else-if="$route.params.id"
						:src="walkPost.image"
						max-width="500"
					></v-img>

					<!-- Image -->
					<v-file-input
						accept="image/png, image/jpeg, image/bmp"
						:placeholder="$t('image')"
						prepend-icon="mdi-camera"
						:label="$t('image')"
						@change="previewImage"
						@click:clear="clearImage"
						v-model="walkPost.image"
					>
					</v-file-input>

					<!-- Title -->
					<v-text-field
						id="title"
						:label="`${$t('title')}*`"
						name="title"
						type="text"
						v-model="walkPost.title"
						:rules="titleRules"
						required
					></v-text-field>

					<!-- Description -->
					<v-textarea
						id="description"
						:label="`${$t('description')}*`"
						name="description"
						type="text"
						v-model="walkPost.description"
						rows="2"
						auto-grow
						:rules="descriptionRules"
						required
					></v-textarea>

					<!-- City -->
					<v-text-field
						id="city"
						:label="`${$t('city')}*`"
						name="city"
						type="text"
						v-model="walkPost.city"
						:rules="cityRules"
						required
					></v-text-field>

					<!-- District -->
					<v-text-field
						id="disctrict"
						:label="`${$t('district')}*`"
						name="disctrict"
						type="text"
						v-model="walkPost.district"
						:rules="districtRules"
						required
					></v-text-field>

					<!-- Contact -->
					<v-textarea
						id="contact"
						:label="`${$t('contacts')}*`"
						name="contact"
						type="text"
						v-model="walkPost.contact"
						rows="2"
						auto-grow
						:rules="contactRules"
						required
					></v-textarea>

					<!-- Error Messages -->
					<v-alert v-model="generalErr" type="error" dense dismissible>
						{{ $t('generalError') }}
					</v-alert>

					<!-- Success Messages -->
					<v-alert v-model="updateSuccess" type="success" dense dismissible>
						{{ $t('updateSuccess') }}
					</v-alert>
				</v-form>

				<!-- Loader -->
				<div v-if="isLoading" class="text-center">
					<v-progress-circular
						indeterminate
						color="primary"
					></v-progress-circular>
				</div>
				<small>*{{ $t('requiredFields') }}</small>
			</v-card-text>

			<v-card-actions>
				<v-btn
					v-if="$route.params.id"
					color="red darken-2"
					dark
					@click="dialogDelete = true"
				>
					{{ $t('delete') }}
				</v-btn>
				<v-spacer></v-spacer>
				<v-btn color="grey" dark @click="$router.go(-1)">
					{{ $t('back') }}
				</v-btn>
				<v-btn color="primary" dark @click="save">
					{{ $t('save') }}
				</v-btn>
			</v-card-actions>
		</v-card>

		<DeleteModal
			:dialogDelete="dialogDelete"
			:title="$t('confirmDelete')"
			@cancel="closeDelete"
			@ok="deleteConfirm"
		/>
	</v-container>
</template>

<script>
export default {
	name: 'WalkPostCreateEdit',
	components: {
		DeleteModal: () => import('@/components/DeleteModal'),
	},

	data() {
		return {
			url: process.env.VUE_APP_BASE_URL || 'http://localhost:5000/api/v1',
			isLoading: false,
			imageUrl: null,
			walkPost: {
				image: null,
				title: '',
				description: '',
				contact: '',
				city: '',
				district: '',
			},
			titleRules: [v => !!v || this.$i18n.t('requiredField')],
			imageRules: [v => !!v || this.$i18n.t('requiredField')],
			descriptionRules: [v => !!v || this.$i18n.t('requiredField')],
			contactRules: [v => !!v || this.$i18n.t('requiredField')],
			cityRules: [v => !!v || this.$i18n.t('requiredField')],
			districtRules: [v => !!v || this.$i18n.t('requiredField')],
			dialogDelete: false,
			updateSuccess: false,
			generalErr: false,
		};
	},
	computed: {
		formTitle() {
			return !this.$route.params.id
				? this.$t('createWalkPost')
				: this.$t('editWalkPost');
		},
	},
	methods: {
		previewImage(image) {
			this.imageUrl = URL.createObjectURL(image);
		},
		clearImage() {
			this.imageUrl = null;
		},
		async save() {
			this.$refs.form.validate();

			if (!this.$refs.form.validate()) {
				return;
			}

			this.isLoading = true;

			let formData = new FormData();

			formData.append(`file`, this.walkPost.image);
			formData.append('title', this.walkPost.title);
			formData.append('description', this.walkPost.description);
			formData.append('contact', this.walkPost.contact);
			formData.append('city', this.walkPost.city);
			formData.append('district', this.walkPost.district);

			if (this.$route.params.id) {
				try {
					await this.$http.put(
						`${this.url}/walkPosts/${this.$route.params.id}`,
						formData,
					);

					this.updateSuccess = true;
				} catch (error) {
					console.error(error);
					this.generalErr = true;
				} finally {
					this.isLoading = false;
				}
			} else {
				try {
					await this.$http.post(`${this.url}/walkPosts`, formData);
					this.$router.go(-1);
				} catch (error) {
					console.error(error);
					this.generalErr = true;
				} finally {
					this.isLoading = false;
				}
			}
		},
		closeDelete() {
			this.dialogDelete = false;
		},
		deleteConfirm() {
			this.deleteWalkPost();
			this.dialogDelete = false;
		},
		async deleteWalkPost() {
			this.isLoading = true;

			try {
				await this.$http.delete(`${this.url}/walkPosts/${this.walkPost._id}`);

				this.$router.go(-1);
			} catch (error) {
				console.error(error);
			} finally {
				this.isLoading = false;
			}
		},
	},
	async mounted() {
		if (this.$route.params.id) {
			this.isLoading = true;

			try {
				const res = await this.$http.get(
					`${this.url}/walkPosts/${this.$route.params.id}`,
				);
				Object.assign(this.walkPost, res.data.data);
				this.walkPost.image = null;
			} catch (error) {
				console.error(error);
			} finally {
				this.isLoading = false;
			}
		}
	},
};
</script>

<style lang="scss" scoped>
// Wysywyg
@import '~vue-wysiwyg/dist/vueWysiwyg.css';
</style>
